import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { navbarWidths, transitionDelay, transitionSpeed, navbarPadding } from "./lib";

const colorBasicWhite = colorPalette.basic.white.hex;
const paddingTransition = `padding ${transitionSpeed}ms`;
const widthTransition = `width ${transitionSpeed}ms`;

const useAppStyles = (expand) => {
  const currentOverflowX = expand ? "hidden" : "visible";
  const currentWidth = expand ? navbarWidths.expanded : navbarWidths.collapsed;
  const currentPadding = expand ? navbarPadding.expanded : navbarPadding.collapsed;
  const svgWidth = expand ? "57px" : "48px";
  const svgHeight = expand ? "13px" : "11px";

  return makeStyles(() => ({
    container: {
      backgroundColor: colorPalette.brand.gartnerBlue.hex,
      color: colorPalette.basic.black.hex,
      height: "100%",
      left: 0,
      position: "fixed",
      transition: widthTransition,
      "&.collapsed": {
        transitionDelay: `${transitionDelay}ms`,
      },
      width: currentWidth,
      zIndex: 1200,
      display: "flex",
      flexDirection: "column",
    },
    logo: {
      marginTop: 22,
      overflowX: currentOverflowX,
      textAlign: "center",
      width: currentWidth,
    },
    logoAnimationContainer: {
      height: 134,
      minHeight: 134,
      padding: currentPadding,
      transition: paddingTransition,
      "& svg": {
        marginBottom: "11px",
        width: svgWidth,
        height: svgHeight,
        transition: `all ${transitionSpeed}ms`,
      },
    },
    "@global": {
      ".main-nav": {
        height: "auto",
        position: "absolute",
        top: 0,
      },
      ".main-nav-collapse .has-main-nav": {
        marginLeft: "0px",
      },
      ".main-nav-expand .has-main-nav": {
        marginLeft: "174px",
      },
      ".main-nav-button:hover svg path": {
        fill: colorBasicWhite,
      },
      ".main-nav-button:hover .main-nav-button-text": {
        color: colorBasicWhite,
      },
      ".main-nav-expand .has-main-nav, .main-nav-collapse .has-main-nav": {
        transition: `margin-left ${transitionSpeed}ms`,
      },
    },
  }));
};

export default useAppStyles;
