import React from "react";
import { IconProps } from "../types";
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const GartnerWordmark = ({
  fillSvg = "none",
  height = "13",
  viewBox = "0 0 57 13",
  width = "57",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      <g clipPath="url(#clip0_15131_9162)">
        <path
          d="M56.435 4.19207L56.27 6.15174H55.469C54.19 6.15174 53.5265 7.17627 53.5265 8.15954V12.5877H51.2608V4.19207H53.4061L53.4543 5.24066C53.9047 4.52211 54.692 4.19207 55.6649 4.19207H56.435Z"
          fill="white"
        />
        <path
          d="M40.7267 12.5877H38.4576V8.14923C38.4576 7.22096 38.3786 6.04859 37.0687 6.04859C35.8104 6.04859 35.3084 6.95623 35.3084 8.01514V12.5877H33.0428V4.19207H35.1434L35.1915 5.26472C35.6419 4.54618 36.4808 3.98578 37.7185 3.98578C40.0048 3.98578 40.7233 5.61884 40.7233 7.31378V12.5877H40.7267Z"
          fill="white"
        />
        <path
          d="M32.0526 10.628L31.8945 12.5396C31.3513 12.684 30.8665 12.739 30.282 12.739C28.1436 12.739 27.5248 11.7591 27.5248 9.78916V1.82671H29.7939V4.19551H32.0492L31.891 6.15517H29.7904V9.61725C29.7904 10.408 30.007 10.8343 30.8184 10.8343C31.1759 10.8343 31.5679 10.7931 32.0526 10.628Z"
          fill="white"
        />
        <path
          d="M26.7821 4.19207L26.6068 6.15174H25.9914C24.7159 6.15174 24.0799 7.17627 24.0799 8.15954V12.5877H21.8108V4.19207H23.9595L24.0042 5.24066C24.4581 4.52211 25.2453 4.19207 26.2183 4.19207H26.7821Z"
          fill="white"
        />
        <path
          d="M11.5792 11.6594C10.0424 12.4811 8.50565 12.9556 6.47722 12.8971C2.71603 12.7837 0 10.4458 0 6.49897C0 2.56931 2.7401 0.0595651 6.47722 0.00111884C8.37157 -0.0263853 10.0665 0.451499 11.3936 1.62386L9.79835 3.23973C8.97666 2.54182 7.77336 2.13269 6.32939 2.19802C3.97779 2.30803 2.56132 4.01672 2.52694 6.36832C2.496 8.91589 4.05686 10.6693 6.49785 10.7346C7.64615 10.7621 8.46783 10.6074 9.18294 10.2877V7.68509H6.64568L6.81758 5.55008H11.5792V11.6594Z"
          fill="white"
        />
        <path
          d="M50.25 9.1875H44.0341C44.2129 10.2602 45.0552 10.9065 46.1038 10.934C47.1249 10.9615 47.6956 10.6521 48.4417 9.89573L49.92 11.0234C48.868 12.3195 47.6062 12.7905 46.1691 12.7905C43.6422 12.7905 41.7478 11.0234 41.7478 8.38645C41.7478 5.75293 43.6319 3.98235 46.0763 3.98235C48.7683 3.98235 50.25 5.6154 50.25 8.51021V9.1875ZM47.9775 7.43411C47.9088 6.37177 47.1146 5.70135 46.0179 5.74604C44.9177 5.7873 44.182 6.50585 44.0307 7.43411H47.9775Z"
          fill="white"
        />
        <path
          d="M20.384 7.09031V12.5877H18.3831L18.3349 11.7591C17.723 12.5189 16.7259 12.794 15.7014 12.794C14.1578 12.794 12.7 12.0342 12.6691 10.2602C12.6244 7.47193 15.8046 7.27597 17.8055 7.27597H18.2043V7.16595C18.2043 6.24456 17.5958 5.74604 16.4475 5.74604C15.6223 5.74604 14.811 6.0761 14.2093 6.63993L12.8994 5.30942C14.089 4.34678 15.3232 3.98578 16.64 3.98578C19.2391 3.98922 20.384 5.18909 20.384 7.09031ZM18.2043 8.92621H17.888C16.8153 8.92965 14.9279 8.98123 14.9347 10.1055C14.9451 11.6869 18.2009 11.185 18.2009 9.27689V8.92621H18.2043Z"
          fill="white"
        />
        <path
          d="M54.6645 11.6113C54.6645 11.0784 55.0667 10.6727 55.5858 10.6727C56.0981 10.6727 56.5004 11.075 56.5004 11.6113C56.5004 12.1545 56.0947 12.5568 55.5858 12.5568C55.0701 12.5568 54.6645 12.1545 54.6645 11.6113ZM55.5858 12.7459C56.2013 12.7459 56.7273 12.268 56.7273 11.6113C56.7273 10.9615 56.2013 10.4871 55.5858 10.4871C54.9636 10.4871 54.4376 10.965 54.4376 11.6113C54.4376 12.268 54.9636 12.7459 55.5858 12.7459ZM55.5721 11.7041L55.9159 12.268H56.1359L55.7646 11.6938C55.9571 11.6698 56.1016 11.5666 56.1016 11.3363C56.1016 11.0784 55.9503 10.965 55.6409 10.965H55.1458V12.268H55.3418V11.7041H55.5721ZM55.3452 11.1334H55.6133C55.7509 11.1334 55.8987 11.1644 55.8987 11.3259C55.8987 11.5254 55.7509 11.5391 55.5824 11.5391H55.3452V11.1334Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_15131_9162">
          <rect width="56.7273" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
