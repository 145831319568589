import { useTranslation } from "react-i18next";
import { useUserState } from "gx-npm-lib";
import HomeButtonComponent from "./home-button/home-button.component";
import BrowseMarketsButtonComponent from "./browse-markets-button/browse-markets-button.component";
import MyEvalsButtonComponent from "./my-evals-button/my-evals-button.component";
import MyTasksButtonComponent from "./my-tasks-button/my-tasks-button.component";
import CurrentEvalSectionComponent from "./current-eval-section/current-eval-section.component";
import styles from "./navigation-buttons-v2.styles.module.scss";

type NavigationButtonsV2Props = {
  isExpanded?: boolean;
};

const NavigationButtonsV2Component = ({ isExpanded = false }: NavigationButtonsV2Props) => {
  const { t } = useTranslation();
  const { hasUserState, hasInitiatives, hasTasks, isEntitled } = useUserState();

  return (
    <nav>
      {hasUserState && (
        <ul aria-label={t("button list")} className={styles.navButtonList}>
          {(isEntitled || hasInitiatives || !hasTasks) && (
            <li className={styles.navButtonItem}>
              <HomeButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          {(isEntitled || hasInitiatives || !hasTasks) && (
            <li className={styles.navButtonItem}>
              <BrowseMarketsButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          {(isEntitled || hasInitiatives || !hasTasks) && (
            <li className={styles.navButtonItem}>
              <MyEvalsButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          {hasTasks && (
            <li className={styles.navButtonItem}>
              <MyTasksButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          <li>
            <CurrentEvalSectionComponent isExpanded={isExpanded} />
          </li>
        </ul>
      )}
    </nav>
  );
};

export default NavigationButtonsV2Component;
