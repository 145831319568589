const HomeButtonIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.9"
        d="M18.9996 13.5357C18.9996 13.0749 19.3732 12.7013 19.8341 12.7013C20.5977 12.7013 20.9602 11.7606 20.394 11.2482L12.6707 4.25856C12.2897 3.91381 11.7096 3.91381 11.3286 4.25856L3.6053 11.2482C3.03912 11.7606 3.4016 12.7013 4.16521 12.7013C4.62605 12.7013 4.99964 13.0749 4.99964 13.5357V19.7457C4.99964 20.298 5.44736 20.7457 5.99964 20.7457H9.99964V16.8232C9.99964 16.2709 10.4474 15.8232 10.9996 15.8232H12.9996C13.5519 15.8232 13.9996 16.2709 13.9996 16.8232V20.7457H17.9996C18.5519 20.7457 18.9996 20.298 18.9996 19.7457V13.5357Z"
        fill="none"
      />
      <path
        d="M19.266 13.6805C19.266 13.2127 19.6538 12.8334 20.1321 12.8334C20.9247 12.8334 21.301 11.8784 20.7133 11.3582L12.6965 4.26248C12.3011 3.91251 11.6989 3.91251 11.3035 4.26248L3.28669 11.3582C2.699 11.8784 3.07525 12.8334 3.86788 12.8334C4.34623 12.8334 4.73402 13.2127 4.73402 13.6805V19.9848C4.73402 20.5455 5.19875 21 5.77202 21H9.924V17.0179C9.924 16.4572 10.3887 16.0027 10.962 16.0027H13.038C13.6113 16.0027 14.076 16.4572 14.076 17.0179V21H18.228C18.8013 21 19.266 20.5455 19.266 19.9848V13.6805Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default HomeButtonIcon;
