const MyEvalsButtonIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13C3.44772 13 3 13.4477 3 14V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V14C21 13.4477 20.5523 13 20 13H4ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H11.2857C11.838 18 12.2857 17.5523 12.2857 17C12.2857 16.4477 11.838 16 11.2857 16H7Z"
        fill="#BBB9B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C3.44772 3 3 3.44772 3 4V10C3 10.5523 3.44772 11 4 11H20C20.5523 11 21 10.5523 21 10V4C21 3.44772 20.5523 3 20 3H4ZM6 7C6 6.44772 6.44772 6 7 6H16.4286C16.9809 6 17.4286 6.44772 17.4286 7C17.4286 7.55228 16.9809 8 16.4286 8H7C6.44772 8 6 7.55228 6 7Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default MyEvalsButtonIcon;
