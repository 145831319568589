import classNames from "classnames";
import { GartnerWordmark } from "gx-npm-icons";
import { publish } from "gx-npm-messenger-util";
import React, { Fragment, useEffect, useState } from "react";
import { NAVBAR_WIDTH } from "./app.constants";
import useAppStyles from "./app.styles.hook";
import AnalyticEveryoneReadyEventSetup from "./components/analytics/analytic-everyone-ready-event-setup.component";
import FooterButtonsV2Component from "./components/footer-buttons-v2/footer-buttons-v2.component";
import LogoAnimationComponent from "./components/logo/logo-animation.component";
import NavigationButtonsV2Component from "./components/navigation-buttons-v2/navigation-buttons-v2.component";

const App = () => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    publish("USER_STATE_LOAD");
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (e && e.clientX > NAVBAR_WIDTH.EXPANDED) {
        setExpand(false);
      }
    };
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const useStyles = useAppStyles(expand);
  const classes = useStyles();
  const containerClass = expand ? "expanded" : "collapsed";
  return (
    <Fragment>
      <AnalyticEveryoneReadyEventSetup />
      <section
        aria-label={`side nav ${containerClass}`}
        onMouseEnter={() => setExpand(true)}
        onMouseLeave={() => setExpand(false)}
        className={classNames(classes.container, containerClass)}
      >
        <div className={classNames(classes.logoAnimationContainer)}>
          <GartnerWordmark />
          <LogoAnimationComponent expand={expand} />
        </div>
        <NavigationButtonsV2Component isExpanded={expand} />
        <FooterButtonsV2Component isExpanded={expand} />
      </section>
    </Fragment>
  );
};
export default App;
