import { createStateSubscription, destroyStateSubscription, updateStateSubject } from "gx-npm-messenger-util";
import React, { Fragment, useEffect } from "react";
import { getUserIntercomAnalytics } from "../../lib/apis";

const AnalyticEveryoneReadyEventSetup = () => {
  useEffect(() => {
    getUserIntercomAnalytics();
  }, []);

  useEffect(() => {
    const subscriptionReloadIntercom = createStateSubscription(
      "RELOAD_INTERCOM_FOR_PROFILE_REFRESH",
      (payload = { isRefreshRequired: true }) => {
        if (payload.isRefreshRequired) {
          getUserIntercomAnalytics();
          updateStateSubject("RELOAD_INTERCOM_FOR_PROFILE_REFRESH", { isRefreshRequired: false });
        }
      }
    );
    return () => {
      destroyStateSubscription(subscriptionReloadIntercom);
    };
  }, []);

  return <Fragment />;
};

export default AnalyticEveryoneReadyEventSetup;
