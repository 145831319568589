import { Fade } from "@mui/material";
import { ReactElement } from "react";

const DEFAULT_FADE_DURATION = 150;
const DEFAULT_FADE_INITIAL_DELAY = 450;

type FadeContainerProps = {
  in?: boolean;
  children: ReactElement;
  timeout?: number;
  transitionDelay?: number;
};

const FadeContainerComponent = ({
  children,
  in: _in = false,
  timeout = DEFAULT_FADE_DURATION,
  transitionDelay = DEFAULT_FADE_INITIAL_DELAY,
}: FadeContainerProps) => {
  return (
    <Fade in={_in} timeout={timeout} style={{ transitionDelay: `${transitionDelay}ms` }}>
      {children}
    </Fade>
  );
};

export default FadeContainerComponent;
