const CurrentEvalSectionIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3C5.44772 3 5 3.44772 5 4V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V4C19 3.44772 18.5523 3 18 3H6ZM8 14.66V16.5C8 16.7761 8.22386 17 8.5 17H10.3375L14.8194 12.5265L12.4741 10.1812L8 14.66ZM16.858 10.4916L15.5271 11.82L13.1808 9.47372L14.511 8.14211C14.7003 7.95263 14.9937 7.95263 15.183 8.14211L16.858 9.81895C17.0473 10.0084 17.0473 10.3021 16.858 10.4916Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default CurrentEvalSectionIcon;
