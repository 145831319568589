import { makeStyles } from "@material-ui/core";
import { transitionSpeed } from "../../lib";

const useLogoStyles = (expand) => {
  const logoHeight = expand ? "35px" : "32px";
  const logoWidth = expand ? "127px" : "116px";

  return makeStyles(() => ({
    animationWrapper: {
      height: logoHeight,
      marginLeft: "-1px",
      marginTop: "-1px",
      transition: `all ${transitionSpeed}ms`,
      width: logoWidth,
    },
  }))();
};

export default useLogoStyles;
