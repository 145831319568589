const HelpCenterButtonIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 4.5C15.55 4.5 13.45 4.9 12 6C10.55 4.9 8.45 4.5 6.5 4.5C5.05 4.5 3.51 4.72 2.22 5.29C1.49 5.62 1 6.33 1 7.14V18.42C1 19.72 2.22 20.68 3.48 20.36C4.46 20.11 5.5 20 6.5 20C8.06 20 9.72 20.26 11.06 20.92C11.66 21.22 12.34 21.22 12.93 20.92C14.27 20.25 15.93 20 17.49 20C18.49 20 19.53 20.11 20.51 20.36C21.77 20.69 22.99 19.73 22.99 18.42V7.14C22.99 6.33 22.5 5.62 21.77 5.29C20.49 4.72 18.95 4.5 17.5 4.5ZM21 17.23C21 17.86 20.42 18.32 19.8 18.21C19.05 18.07 18.27 18.01 17.5 18.01C15.8 18.01 13.35 18.66 12 19.51V8C13.35 7.15 15.8 6.5 17.5 6.5C18.42 6.5 19.33 6.59 20.2 6.78C20.66 6.88 21 7.29 21 7.76V17.23Z"
        fill="#BBB9B8"
      />
      <path
        d="M13.98 11.01C13.66 11.01 13.37 10.81 13.27 10.49C13.14 10.1 13.36 9.67 13.75 9.55C15.29 9.05 17.28 8.89 19.11 9.1C19.52 9.15 19.82 9.52 19.77 9.93C19.72 10.34 19.35 10.64 18.94 10.59C17.32 10.4 15.55 10.55 14.21 10.98C14.13 10.99 14.05 11.01 13.98 11.01Z"
        fill="#BBB9B8"
      />
      <path
        d="M13.98 13.67C13.66 13.67 13.37 13.47 13.27 13.15C13.14 12.76 13.36 12.33 13.75 12.21C15.28 11.71 17.28 11.55 19.11 11.76C19.52 11.81 19.82 12.18 19.77 12.59C19.72 13 19.35 13.3 18.94 13.25C17.32 13.06 15.55 13.21 14.21 13.64C14.13 13.66 14.05 13.67 13.98 13.67Z"
        fill="#BBB9B8"
      />
      <path
        d="M13.98 16.33C13.66 16.33 13.37 16.13 13.27 15.81C13.14 15.42 13.36 14.99 13.75 14.87C15.28 14.37 17.28 14.21 19.11 14.42C19.52 14.47 19.82 14.84 19.77 15.25C19.72 15.66 19.35 15.95 18.94 15.91C17.32 15.72 15.55 15.87 14.21 16.3C14.13 16.32 14.05 16.33 13.98 16.33Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default HelpCenterButtonIcon;
