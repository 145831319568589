import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import VisitGartnerButtonIcon from "./visit-gartner-button.icon";
import FadeContainerComponent from "../../ui/fade-container.component";
import styles from "./visit-gartner-button.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";
import React from "react";

type VisitGartnerButtonComponentProps = {
  isExpanded?: boolean;
};

const VisitGartnerButtonComponent = ({ isExpanded = false }: VisitGartnerButtonComponentProps) => {
  const { t } = useTranslation();
  const buttonUrlPath = "https://www.gartner.com";
  const tabLabel = t("Visit Gartner.com");

  return (
    <FadeContainerComponent in={isExpanded}>
      <a
        aria-label={tabLabel}
        tabIndex={0}
        href={buttonUrlPath}
        target="_blank"
        rel="noreferrer"
        className={styles.itemButton}
      >
        <div className={styles.itemButtonIconContainer}>
          <VisitGartnerButtonIcon />
        </div>
        <div className={styles.itemButtonTextContainer}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={styles.itemButtonText} boldness={"medium"} styling={"p2"}>
                {tabLabel}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent rootClassName={styles.itemButtonText} type={"p2"}>
                {tabLabel}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </a>
    </FadeContainerComponent>
  );
};

export default VisitGartnerButtonComponent;
