const transitionDelay = 150;
const transitionSpeed = 600;

const navbarWidths = {
  collapsed: 76,
  expanded: 250,
};

const navbarPadding = {
  collapsed: "26px 0 0 12px",
  expanded: "26px 0 0 26px",
  left: 14,
  right: 14,
};

export { navbarWidths, transitionDelay, transitionSpeed, navbarPadding };
