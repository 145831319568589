import { getAsyncRequest } from "gx-npm-lib";

/**
 * @param {number} apiVersion
 * @param {Function} setIsComplete
 */
const getUserIntercomAnalytics = async (setIsComplete = (_isComplete) => {}) => {
  const response = await getAsyncRequest(`/api/v3/user/analytics/intercom`);
  if (response.status === 200 && response?.data?.data) {
    const {
      browsingReason,
      email,
      fullName,
      hasNamedInitiative,
      latestInitCreatedDate,
      latestInitTemplateTechName,
      userHMAC,
      userIdHash,
      hasScoredInitiative,
      hasVendorSurveyResponse,
      hasViewerRole,
      hasOwnerRole,
      hasContributorRole,
    } = response.data.data;
    if (email && typeof window.dataLayer === "object") {
      window.dataLayer.push({
        event: "user-analytics-intercom-hook",
        USER_BROWSING_REASON: browsingReason || "",
        USER_EMAIL: email,
        USER_FULL_NAME: fullName,
        USER_HAS_NAMED_INIT: hasNamedInitiative,
        USER_HAS_SCORED_INIT: hasScoredInitiative,
        USER_HAS_VENDOR_SURVEY_RESPONSE: hasVendorSurveyResponse,
        USER_HAS_VIEWER_ROLE: hasViewerRole,
        USER_HAS_OWNER_ROLE: hasOwnerRole,
        USER_HAS_CONTRIBUTOR_ROLE: hasContributorRole,
        USER_HASH: userIdHash,
        USER_INTERCOM_HMAC: userHMAC,
        USER_LATEST_UNNAMED_INIT_CREATE_DATE: latestInitCreatedDate,
        USER_LATEST_UNNAMED_INIT_TECH_NAME: latestInitTemplateTechName,
      });
      window.dataLayer.push({
        event: "everyone-ready",
        USER_BROWSING_REASON: browsingReason || "",
        USER_EMAIL: email,
        USER_FULL_NAME: fullName,
        USER_HAS_NAMED_INIT: hasNamedInitiative,
        USER_HAS_SCORED_INIT: hasScoredInitiative,
        USER_HAS_VENDOR_SURVEY_RESPONSE: hasVendorSurveyResponse,
        USER_HAS_VIEWER_ROLE: hasViewerRole,
        USER_HAS_OWNER_ROLE: hasOwnerRole,
        USER_HAS_CONTRIBUTOR_ROLE: hasContributorRole,
        USER_HASH: userIdHash,
        USER_INTERCOM_HMAC: userHMAC,
        USER_LATEST_UNNAMED_INIT_CREATE_DATE: latestInitCreatedDate,
        USER_LATEST_UNNAMED_INIT_TECH_NAME: latestInitTemplateTechName,
      });
    }
  }
  setIsComplete(true);
  if (window.Intercom) {
    window.Intercom("boot");
  }
};

export { getUserIntercomAnalytics };
