import { Fragment, ReactNode, useContext } from "react";
import { FeatureFlagContext } from "./feature-flag.context";

type FeatureFlagsAvailableContainerProps = {
  children: ReactNode | ReactNode[];
};

/**
 * feature flag content wrapper for displaying content once feature flags have been loaded
 * @param children - content to display within context
 * @returns children props when feature flag provided is passed and indicates should be displayed
 */
export const FeatureFlagsAvailableContainer = ({ children }: FeatureFlagsAvailableContainerProps) => {
  const { flags } = useContext(FeatureFlagContext);
  if (Object.keys(flags).length > 0) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
};
