import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import classnames from "classnames";
import PropTypes from "prop-types";
import BuySmartLogoAnimation from "../../assets/lottie/buySmartLogoAnimation.json";
import useLogoStyles from "./logo-animation.styles";

const propTypes = { expand: PropTypes.bool };
const LogoAnimationComponent = ({ expand = false }) => {
  const classes = useLogoStyles(expand);
  const { t } = useTranslation();
  const [play, setPlay] = useState(false);
  const [direction, setDirection] = useState(0);
  const [animationDirection, setAnimationDirection] = useState(expand ? "expanded" : "collapsed");
  const [animationStatus, setAnimationStatus] = useState("complete");

  useEffect(() => {
    if (expand) {
      setDirection(1);
    } else {
      setDirection(-1);
    }
  }, [expand]);

  useEffect(() => {
    if (direction === 1 && animationDirection !== "expanded") {
      setAnimationDirection("expanded");
      setAnimationStatus("playing");
      setPlay(true);
    } else if (direction === -1 && animationDirection !== "collapsed") {
      setAnimationDirection("collapsed");
      setAnimationStatus("playing");
      setPlay(true);
    }
  }, [direction, animationDirection]);

  return (
    <div
      aria-label={t("BuySmart logo")}
      className={classnames("gx-logo-animation", animationDirection, animationStatus)}
    >
      <Lottie
        animationData={BuySmartLogoAnimation}
        className={classes.animationWrapper}
        direction={direction}
        loop={false}
        onComplete={() => {
          setPlay(false);
          setAnimationStatus("complete");
        }}
        play={play}
      />
    </div>
  );
};

LogoAnimationComponent.propTypes = propTypes;
export default LogoAnimationComponent;
