const MyTasksButtonIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 6C3.5 5.44772 3.94772 5 4.5 5H8.5C9.05228 5 9.5 5.44772 9.5 6V10C9.5 10.5523 9.05228 11 8.5 11H4.5C3.94772 11 3.5 10.5523 3.5 10V6ZM5.5 7V9H7.5V7H5.5ZM12.5 8C12.5 7.44772 12.9477 7 13.5 7H21.0513C21.6036 7 22.0513 7.44772 22.0513 8C22.0513 8.55228 21.6036 9 21.0513 9H13.5C12.9477 9 12.5 8.55228 12.5 8ZM12.5 16C12.5 15.4477 12.9477 15 13.5 15H21.0513C21.6036 15 22.0513 15.4477 22.0513 16C22.0513 16.5523 21.6036 17 21.0513 17H13.5C12.9477 17 12.5 16.5523 12.5 16ZM6.20711 19.2929C5.81658 19.6834 5.18342 19.6834 4.79289 19.2929L2.70584 17.2058C2.31602 16.816 2.31602 16.184 2.70585 15.7942C3.09501 15.405 3.72574 15.4042 4.11584 15.7925L4.80384 16.4772C5.18871 16.8602 5.81088 16.8598 6.19525 16.4763L9.38462 13.2938C9.77431 12.905 10.4053 12.9053 10.7946 13.2946C11.1842 13.6842 11.1842 14.3158 10.7946 14.7054L6.20711 19.2929Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default MyTasksButtonIcon;
