import React from "react";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import App from "./app.component";
import { BrowserRouter } from "react-router-dom";

const generateClassName = createGenerateClassName({
  seed: "main-nav-app",
});

export default function Root() {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StylesProvider>
    </FeatureFlagProvider>
  );
}
