const BrowseMarketsButtonIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C10.6739 1 9.40215 1.52678 8.46447 2.46447C7.52678 3.40215 7 4.67392 7 6L4 6C2.89 6 2 6.89 2 8V19C2 20.1 2.89 21 4 21H11.81C10.69 19.83 10 18.25 10 16.5C10 14.7761 10.6848 13.1228 11.9038 11.9038C13.1228 10.6848 14.7761 10 16.5 10C18.82 10 20.85 11.21 22 13.03V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6H17C17 4.67392 16.4732 3.40215 15.5355 2.46447C14.5979 1.52678 13.3261 1 12 1ZM15 6C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6L15 6Z"
        fill="#BBB9B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 12C19 12 21 14 21 16.5C21 17.38 20.75 18.21 20.31 18.9L23.39 22L22 23.39L18.88 20.32C18.19 20.75 17.37 21 16.5 21C14 21 12 19 12 16.5C12 14 14 12 16.5 12ZM16.5 14C15.837 14 15.2011 14.2634 14.7322 14.7322C14.2634 15.2011 14 15.837 14 16.5C14 17.163 14.2634 17.7989 14.7322 18.2678C15.2011 18.7366 15.837 19 16.5 19C17.163 19 17.7989 18.7366 18.2678 18.2678C18.7366 17.7989 19 17.163 19 16.5C19 15.837 18.7366 15.2011 18.2678 14.7322C17.7989 14.2634 17.163 14 16.5 14Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default BrowseMarketsButtonIcon;
